import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { Provider } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';
import history from 'utils/history';
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import Modal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import configureStore from './configureStore';
const Zoom = cssTransition({
  enter: 'zoomIn',
  exit: 'zoomOut',
  duration: [500, 800],
});
const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
// const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const initialState = {};
const store = configureStore(initialState, history);

const App = () => {
  const [logoutModal, setLogoutModal] = useState(false);
  const [modal, setModal] = useState(false);
  useEffect(() => {
    store.subscribe(() => {
      const storedata = store.getState();
      if (
        storedata &&
        storedata.authentication &&
        storedata.authentication.loggingOut
      )
        setLogoutModal(true);
    });
    // console.log(store.getState());
    window.addEventListener('beforeunload', (event) => {
      setModal(true);
    });
  }, []);
  return (
    <Provider store={store}>
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <ToastContainer
            autoClose={3000}
            position={toast.POSITION.TOP_RIGHT}
            type={toast.TYPE.INFO}
            // transition={Zoom}
            pauseOnHover={false}
            closeOnClick={true}
            draggable={true}
            draggablePercent={20}
            toastClassName="toast-container"
            progressClassName="toast-progress-style"
          />
          <Modal
            isOpen={modal}
            // onAfterOpen={this.afterOpenModal}
            // onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            {logoutModal && (
              <Card style={{ marginBottom: '0px' }}>
                <CardHeader>Session Timeout </CardHeader>
                <CardBody>
                  <h2>Logging out...</h2>
                </CardBody>
              </Card>
            )}
          </Modal>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            {/* <Route
                exact
                path="/register"
                name="Register Page"
                render={props => <Register {...props} />}
              /> */}
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    </Provider>
  );
};

export default App;
